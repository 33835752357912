<template>
    <div class="ProjectDisplay">
        <div class="top-box">
            <div class="content-box">
                <h1>智慧城市</h1>
                <p>
                    智慧城市狭义地说是使用各种先进的技术手段尤其是信息技术手段改善城市状况，
                    使城市生活便捷;广义上理解应是尽可能优化整合各种资源，城市规划、建筑让人赏心悦目，
                    让生活在其中的市民可以陶冶性情心情愉快而不是压力，总之是适合人的全面发展的城市。
                    智慧城市是新一代信息技术支撑，知识社会下一代创新(创新2.0)环境下的城市形态。
                    它基于全面透彻的感知，宽带泛在的互联以及智能融合的应用，
                    构建有利于创新涌现的制度环境与生态，实现以用户创新、开放创新、大众创新、协同创新为特征的以人为本可持续创新，
                    塑造城市公共价值并为生活其中的每一位市民创造独特价值，实现城市与区域可持续发展。
                    可以说，智慧城市就是以智慧的理念规划城市，以智慧的方式建设城市，以智慧的手段管理城市，
                    用智革的方式发展城市，从而提高城市空间的可达性，使城市更加具有活力和长足的发展。
                </p>
            </div>
            <div class="text-box">
                <div class="items">
                    <p class="p1">
                        一、全面透彻的感知。通过传感技术，实现对城市管理各方面监测和全面感知。
                        智慧城市利用各类随时随地的感知设备和智能化系统，智能识别、立体感知城市环境，状态，
                        位置等信息的全方位变化，对感知数据进行融合，分析和外理，并能与业务流程智能能化集成，
                        继而主动做出响应，促进城市各个关键系统和谐高效的运行
                    </p>
                </div>
                <div class="items">
                    <p  class="p1">
                        二、宽带泛在的互联。各类宽带有线、无线网络技术的发展为城市中物与物、人与物、人与人的全面互联、
                        互通、互动，为城市各类随时、随地、随需、随意应用提供了基础条件。
                        宽带泛在网络作为智慧城市的“神经网络”，极大的增强了智慧城市作为自适应系统的信息获取、实时反馈、随时随地智能服务的能力。
                    </p>
                </div>
                <div class="items">
                    <p class="p1">
                        三、智能融合的应用。现代城市及其管理是一类开放的复杂巨系统，新一代全面感知技术的应用更增加了城市的海量数据。
                        集大成，成智慧。基于云计算，通过智能融合技术的应用实现对海量数据的存储、计算与分析，并引入综合集成法，
                        通过人的“智慧”参与，提升决策支持和应急指挥的能力。基于云计算平台的大成智慧工程将构成智慧城市的“大脑”。
                        技术的融合与发展还将进一步推动“云”与“端”的结合，推动从个人通讯，个人计算到个人制造的发展，
                        推动实现智能融合，随时，随地，随需，随意的应用进一步彰昂个人的参与和用户的力量
                    </p>
                </div>
                <div class="items">
                    <p class="p1">
                        四、以人为本的可持续创新。面向知识社会的下一代创新重塑了现代科技以人为本的内涵，
                        也重新定义了创新中用户的角色、应用的价值、协同的中内涵和大众的力量。智慧城市的建设尤其注重以人为本、
                        市民参与、社会协同的开放创新空间的塑造以及公共价值与独特价值的创造。注重从市民需求出发，强化用户的参与，
                        汇聚公众智慧，不断推动用户创新近，开放创新，大众创新，协同创新，以人为本实现经济，社会，环境的可持续发展。
                    </p>
                </div>
            </div>
        </div>
        <div class="top-box">
            <div class="content-box">
                <h1>通用数据接口开发项目</h1>
                <p>
                        基于消息中间件的接口机制主要通过消息传递来完成系统之间的协作和通信通过消息中间件把应用扩展到不同的操作系统和不同的网络环境。
                    通过使用可靠的消息队列，提供支持消息传递所需的目录、安全和管理服务。
                    当一个事件发生时，消息中间件通知服务方应该进行何种操作。
                    其核心安装在需要进行消息传递的系统上，在它们之间建立逻辑通道，由消息中间件实现消息发送。
                    消息中间件可以支持同步方式和异步方式，实际上是一种点到点的机制，因而可以很好的适用于面向对象的编程方式。
                </p>
            </div>
        </div>
        <div class="top-box">
            <div class="content-box">
                <h1>增强流媒体设备接入模块建设项目</h1>
                <p>
                        业务流与音视频流趋于融合，音视频流从佐证材料逐渐向业务本身在演进，
                    业务的快速发展对视频平台提出了高的要求，当前的视频平台在设备接入兼容性、
                    协议适配、视频调阅速度、应用场景支撑等方面的能力仍有待提升。
                    为加强对各专业音视频业务的支撑，构建的“开放式、大融合、高性能”的流媒体技术中台势在必行，
                    增强流媒体对所有视频、图片、音频数据的接入、存储、调阅的中间组件，
                    为业务系统提供高性能的视频管理、媒体访问、视频存储和视频加速等功能，
                    融合人工智能平台可提供二次高可用、深加工的流媒体分析数据的音视频底座平台。
                </p>
            </div>
        </div>
        <div class="top-box">
            <div class="content-box">
                <h1> 大数据云平台项目</h1>
                <p>
                        随着云计算、虚拟化、大数据分析等先进信息化技术在科研领域的广泛应用，
                    将有效解决了当前信息化建设面临的一系列问题，
                    通过采用先进的云计算和虚拟化技术，构建一个资源可按需定制、动态高效、灵活扩展、全面管理、稳定可靠、
                    可持续发展的新一代云计算业务部署和服务交付的“大数据云平台”，
                    为研究中心及各科研项目提供安全可靠、弹性扩展的基础运行环境，
                    可以解决数据中心基础设施资源的整合及合理利用,从而有效提高了基础设施的资源利用率，
                    简化了管理维护流程，提升了科研工作效率。
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    }
}
</script>
<style lang="less" scoped>
.ProjectDisplay{
    width: 100%;
    height: 100%;
}
.top-box{
    width:90%;
    margin: 0 auto;
    .content-box{
        width:100%;
        p{
            text-indent: 20px;
            font-size: 1.3rem;
            color: #878787;
        }
    }
    .right-img{
        flex: 1;
        margin:0 0 0 150px;
    }
    .right-img>img{
        width:688px;
        height:450px;
        background-size: 100% 100%;
    }
    
}
.bt-box{
    width:100%;
    height:80px;
    margin: 0 auto;
    background-color: #f5f5f5;
}
.bt-box>h1{
    margin: 0;
    line-height: 80px;
    padding: 0 0 0 100px;
}
.text-box{
    width:95%;
    margin: 0 auto;
}
.items{
    display: flex;
}
.items>p{
    margin: 0;
    font-size:18px;
    color: #878787;
}
.items>.p1{
    padding:10px 0 0 0;
}


</style>